<template>
	<my-dialog :title="dialogTitle" ref="dialog" @close="onDialogClose">

		<template #activator="{attrs,on}">
			<slot name="activator" v-bind="{attrs,on}">
				<v-btn v-bind="attrs" v-on="on" small :disabled="!enabled" :color="enabled ? null : 'error'">
					{{ enabled ? 'Избери час' : 'Въведете продължителност' }}
				</v-btn>
			</slot>
		</template>

		<template #default="{state}">
			<schedule-select-input
				v-if="state"
				:schedule-id.sync="scheduleIdInner"
				:time-start.sync="timeStartInner"
				:duration="duration"
				:imot-id="imotId"
				:record-id="recordId"
				@change="onChange"
				max-height="calc(90vh - 120px)"
			/>
		</template>

	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import {isoToDate} from "@/ittijs/utils";
import {weekdays, months} from "@/ittijs/Nomenclatures";
import ScheduleSelectInput from "@/views/montaji/job_requests/ScheduleSelectInput.vue";

export default {
	components: {ScheduleSelectInput, MyDialog},
	mixins: [
		SelectedUtilityMixin,
	],
	props: {
		scheduleId: {},
		timeStart: String,
		duration: Number,
		recordId: {}, // za conflict avoid s veche izbrania chas
		imotId: {}, // za zasechka s raionite
		dialogTitle: {
			type: String,
			default: 'Избор от график',
		},
	},
	data(){
		return {
			scheduleIdInner: this.scheduleId,
			timeStartInner: this.timeStart,
			workStart: window.ITTIConfig.montajiWorkStart,
			workEnd: window.ITTIConfig.montajiWorkEnd,
		}
	},
	watch: {
		scheduleId(val){
			this.scheduleIdInner = val;
		},
		timeStart(val){
			this.timeStartInner = val;
		},
	},
	computed: {
		enabled(){
			return !isNaN(this.duration) && this.duration > 0;
		},
	},
	methods: {
		formatDate(date){
			date = isoToDate(date);
			if (date) {
				return `${date.getDate()} ${months[date.getMonth()]} (${weekdays[date.getDay()]})`;
			}
			return null;
		},
		onChange(){
			this.$emit('update:schedule-id', this.scheduleIdInner);
			this.$emit('update:time-start', this.timeStartInner);
			this.$emit('change');
			this.$refs.dialog.doClose();
		},
		onDialogClose() {
			this.scheduleIdInner = this.scheduleId;
			this.timeStartInner = this.timeStart;
		},
	},
}
</script>