<template>
	<v-progress-linear indeterminate v-if="initLoading" />
	<div v-else>
		<v-toolbar dense flat>
			<v-btn-toggle v-model="zoomLevel" dense class="ml-2">
				<v-btn value="month">Месец</v-btn>
				<v-btn value="day">Ден</v-btn>
			</v-btn-toggle>

			<div class="ml-4 text-subtitle-1">{{ title }}</div>

			<v-spacer/>

			<button-set dense v-if="zoomLevel==='month'">
				<v-btn @click="gotoPrev">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<v-btn @click="gotoNext">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</button-set>
		</v-toolbar>
		<v-divider/>
		<data-loader
			:watch="{calStartDate,calEndDate}"
			:callback="()=>loadCalendarData(calStartDate, calEndDate)"
			:data.sync="calendarData"
		/>
		<v-window v-model="zoomLevel" vertical>
			<v-window-item value="month">
				<v-calendar
					ref="calendar"
					type="month"
					v-model="calModel"
					@change="onCalendarChange($event)"
					locale="bg-BG"
					:weekdays="[1,2,3,4,5,6,0]"
				>
					<template #day-label="{day,weekday}">
						<div class="text-subtitle-2">{{ weekdaysShort[weekday] }}</div>
						<div class="text-body-2">{{ day }}</div>
					</template>
					<template #day="{outside,date}">
						<v-sheet v-if="!outside && calendarDataDays[date]" :color="$vuetify.theme.dark ? 'indigo darken-4' : 'indigo lighten-5'">
<!--							<v-btn @click="showDate(date)" tile block :color="$vuetify.theme.dark ? 'indigo darken-2' : 'indigo lighten-3'">График</v-btn>-->
							<template v-for="(item, index) in calendarDataDays[date]">
								<div :key="index">
									<div style="min-height: 200px;">
	<!--									<div class="text-subtitle-2">{{item.type}}</div>-->
										<div class="text-subtitle-1 font-weight-bold">
											<user-info v-for="(userId, index) in item.users_ids" :key="userId" :id="parseInt(userId)">
												<template #default="{user}"><span v-if="index > 0">, </span>{{ user.user }}</template>
											</user-info>
										</div>
										<div class="text-subtitle-1" style="min-height: 120px;" v-text="item.areas.join(', ')" />
										<div class="font-italic my-notes" style="white-space: pre-wrap;"><div class="my-notes-inner">{{ item.notes }}</div></div>
									</div>
									<div class="text-right">
										<v-btn @click="showDate(date)" small class="ma-1"
											:color="$vuetify.theme.dark ? 'indigo darken-2' : 'indigo lighten-3'"
										>График</v-btn>
									</div>
									<dev-type-counts :counts="item.device_counts" />
									<day-jobs-indicator
										:start="workStart"
										:end="workEnd"
										:events="jobsToEvents(item.jobs)"
										:hide-empty="false"
									/>
									<v-divider v-if="index < calendarDataDays[date].length - 1" class="mb-2 mt-1" />
								</div>
							</template>
						</v-sheet>
					</template>
				</v-calendar>
			</v-window-item>
			<v-window-item value="day" class="overflow-x-auto overflow-y-auto" :style="dayWindowStyle">
				<table class="scheduler ma-4" v-if="calendarData">
					<thead>
					<tr class="subheading type">
						<th v-for="item in calendarDataFiltered" :key="item.id">
							{{ item.type }}
						</th>
					</tr>
					<tr class="subheading users">
						<th v-for="item in calendarDataFiltered" :key="item.id">
							<user-info v-for="(userId, index) in item.users_ids" :key="userId" :id="parseInt(userId)">
								<template #default="{user}"><span v-if="index > 0">, </span>{{ user.user }}</template>
							</user-info>
						</th>
					</tr>
					<tr class="subheading areas">
						<th v-for="item in calendarDataFiltered" :key="item.id" width="250">
							{{ item.areas.join(', ') }}
						</th>
					</tr>
					<tr class="subheading notes">
						<th v-for="item in calendarDataFiltered" :key="item.id">
							<div style="white-space: pre-wrap;">{{ item.notes }}</div>
						</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td v-for="(item, index) in calendarDataFiltered" :key="index">
							<day-schedule
								ref="dayInput"
								:start="workStart"
								:end="workEnd"
								:interval-minutes="30"
								:interval-height="48"
								:precision-minutes="5"
								:selection-minutes="duration"
								:events="jobsToEvents(item.jobs)"
								:value="item.id == scheduleId ? timeStart : null"
								@input="onDayInput($event, index)"
							>
								<template #event="{start, end, event}">
									<div class="body-2">
										{{ event.job.address }}<br>
										[{{ event.start.toString() }} - {{ end.toString() }}]
									</div>
								</template>
							</day-schedule>
						</td>
					</tr>
					</tbody>
				</table>
			</v-window-item>
		</v-window>
	</div>
</template>

<script>
import ButtonSet from "@/ittijs/components/ButtonSet.vue";
import DaySchedule from "@/ittijs/components/DaySchedule.vue";
import Model from "./Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import UserInfo from "@/ittijs/components/UserInfo.vue";
import {isoToDate, phpDate, TimeOfDay} from "@/ittijs/utils";
import {months, weekdaysShort} from "@/ittijs/Nomenclatures";
import DayJobsIndicator from "@/views/montaji/schedule/DayJobsIndicator.vue";
import DevTypeCounts from "@/views/montaji/job_requests/DevTypeCounts.vue";

const model = ITTIModelProvider.getModel(Model);

export default {
	components : {DevTypeCounts, DayJobsIndicator, UserInfo, DataLoader, DaySchedule, ButtonSet},
	mixins: [
		SelectedUtilityMixin,
	],
	props: {
		// input props (updated with sync)
		scheduleId: {},
		timeStart: String,
		// config props
		duration: Number,
		recordId: {}, // za conflict avoid s veche izbrania chas
		imotId: {}, // za zasechka s raionite
		maxWidth: {},
		maxHeight: {},
	},
	data(){
		return {
			initLoading: false,
			zoomLevel: this.scheduleId ? 'day' : 'month',
			calModel: null,
			calStartDate: null,
			calEndDate: null,
			calendarData: null,
			calendarTitle: null,
			workStart: window.ITTIConfig.montajiWorkStart,
			workEnd: window.ITTIConfig.montajiWorkEnd,
		}
	},
	computed: {
		title(){
			if (!this.calModel) return '';
			const date = isoToDate(this.calModel);
			switch (this.zoomLevel) {
				case 'month': return `${months[date.getMonth()]} ${date.getFullYear()}`;
				case 'day': return phpDate(window.ITTIConfig.dateFormat, date);
				default: return '';
			}
		},
		calendarDataFiltered(){
			return this.calendarData?.filter(item => item.date === this.calModel) ?? [];
		},
		calendarDataDays(){
			const result = {};
			for (const item of this.calendarData ?? []) {
				if (!result[item.date]) {
					result[item.date] = [];
				}
				result[item.date].push(item);
			}
			return result;
		},
		dayWindowStyle(){
			return {
				maxWidth: this.maxWidth,
				maxHeight: this.maxHeight,
			}
		},
		weekdaysShort() {
			return weekdaysShort
		},
	},
	watch: {
		calModel(val){
			if (val) {
				if (!(this.calStartDate && this.calStartDate <= val)) {
					this.calStartDate = val;
				}
				if (!(this.calEndDate && this.calEndDate >= val)) {
					this.calEndDate = val;
				}
			}
		},
	},
	async mounted() {
		if (this.scheduleId) {
			this.$nextTick(async () => {
				this.initLoading = true;
				const date = await model.getScheduleDate(this.selectedUtility, this.scheduleId);
				this.calModel = date ?? new Date;
				this.initLoading = false;
			});
		}
	},
	methods: {
		jobsToEvents(jobs){
			return jobs
				.filter(job => job.id != this.recordId)
				.map(job => {
					const start = TimeOfDay.fromString(job.scheduled_time_start.substr(0, 5));
					const end = start.addMinutes(parseInt(job.scheduled_duration_minutes));
					return {
						start,
						end,
						job,
					}
				});
		},
		gotoPrev(){
			if (this.zoomLevel==='month') {
				this.$refs.calendar.prev();
			}
			else {
				console.log('prev');
			}
		},
		gotoNext(){
			if (this.zoomLevel==='month') {
				this.$refs.calendar.next();
			}
			else {
				console.log('next');
			}
		},
		onCalendarChange($event){
			this.calStartDate = $event.start.date;
			this.calEndDate = $event.end.date;
		},
		showDate(date){
			this.calModel = date;
			this.zoomLevel = 'day';
		},
		loadCalendarData(start, end){
			return model.getScheduleData(this.selectedUtility, this.imotId, start, end);
		},
		onDayInput(value, index){
			if (value !== null) {
				for (const i in this.$refs.dayInput) {
					if (i != index) {
						this.$refs.dayInput[i].clear();
					}
				}
			}
			const scheduleId = (value===null ? null : this.calendarDataFiltered[index]?.id);
			const timeStart = value;
			this.$emit('update:schedule-id', scheduleId);
			this.$emit('update:time-start', timeStart);
			this.$emit('change');
		},
	},

}
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

::v-deep .v-calendar-weekly__head
	display: none

.my-notes
	height: 60px
	overflow: hidden
	position: relative
	&:hover
		overflow: visible
	&> .my-notes-inner
		position: absolute
		background-color: #ddd
		z-index: 1

table.scheduler::v-deep
	border-collapse: collapse
	&> thead
		&> tr.dates > th
			padding: 4px 8px
			font-weight: normal
			font-size: 110%
			.theme--light &
				background-color: #E0E0E0
				color: #616161
			.theme--dark &
				background-color: #616161
				color: #E0E0E0
		&> tr.subheading > th
			padding: 4px 8px
			font-weight: normal
			.theme--light &
				background-color: #EEEEEE
		&> tr.areas > th
			text-align: left
			vertical-align: top
		&> tr.notes > th
			text-align: left
			vertical-align: top

table.scheduler::v-deep > * > tr > *
	border-style: solid
	border-width: 1px
	.theme--light &
		border-color: map-get($material-light, 'dividers')
	.theme--dark &
		border-color: map-get($material-dark, 'dividers')
</style>