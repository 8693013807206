<template>
	<v-dialog v-model="open" width="700">
		<template v-slot:activator="{ on, attrs }">
			<slot name="activator" v-bind="{ on, attrs }">
				<v-btn right icon small v-bind="attrs" v-on="on">
					<v-icon dense :color="activatorColor">{{ activatorIcon }}</v-icon>
				</v-btn>
			</slot>
		</template>
		<template v-slot:default="">
			<v-card v-if="open">
				<v-toolbar flat dense><v-toolbar-title>Уред</v-toolbar-title></v-toolbar>
				<v-divider/>

				<v-form ref="form">
					<model-form-tpl-side-by-side
						:imodel="model"
						:fields="fields"
						:disabled-fields="disabledFields"
						:style="{maxHeight:'calc(90vh - 120px)',overflowX:'auto'}"
						:data.sync="lazyData"
						:left-col-width="200"
					>

						<template v-if="isReplace" #before-row.dev_old__device_N>
							<v-row no-gutters>
								<v-col>
									<v-sheet rounded class="my-2 px-2 text-subtitle-1 red white--text">
										Стар уред
									</v-sheet>
								</v-col>
							</v-row>
						</template>

						<template v-if="isReplace" #before-row.dev_new__device_type_code>
							<v-row no-gutters>
								<v-col>
									<v-sheet rounded class="my-2 px-2 text-subtitle-1 green white--text">
										Нов уред
									</v-sheet>
								</v-col>
							</v-row>
						</template>

						<template #field.dev_new__device_model_id="{data,errors,disabled}">
							<device-model
								label=""
								outlined
								dense
								name="dev_new__device_model_id"
								hide-details="auto"
								:device-type="data.dev_new__device_type_code"
								:data="data"
								:errors="errors.dev_new__device_model_id"
								:disabled="disabled"
								:radio.sync="isModelRadio"
							/>
						</template>

						<template #field.dev_new__device_N="{data,errors,disabled}">
							<edit-input
								label=""
								outlined dense
								name="dev_new__device_N"
								:data="data"
								:errors="errors"
								:disabled="disabled"
							/>
							<dup-dev-num :number="data.dev_new__device_N" :id="action!=='replace' && device?.id" />
						</template>

						<template #field.dev_new__device_type_code="{data,errors}">
							<device-type-select
								:insert="true"
								label=""
								outlined dense
								name="dev_new__device_type_code"
								:data="data"
								:errors="errors"
								:disabled="true"
							></device-type-select>
						</template>

						<template #field.dev_new__room="{data,errors,disabled}">
							<data-loader :watch="roomOptionsParams" :callback="()=>model.getRoomOptions(roomOptionsParams.utility, roomOptionsParams.device_type_code)">
								<template #default="{data: items}">
									<edit-input
										type="combobox"
										label=""
										outlined dense
										name="dev_new__room"
										:data="data"
										:errors="errors"
										:disabled="disabled"
										:items="items"
									/>
								</template>
							</data-loader>
						</template>

					</model-form-tpl-side-by-side>
				</v-form>

				<v-divider/>
				<v-toolbar flat dense>
					<edit-buttons
						class="flex-grow-1"
						:show-delete="false"
						:show-save="!readonly"
						@close="doClose"
						@save="onClickSave"
					>
					</edit-buttons>
				</v-toolbar>

			</v-card>
		</template>
	</v-dialog>
</template>

<script>
import Devices from '@/views/devices/Model';
import DeviceModel from '../../../views/device_models/DeviceModelSelect';
import EditInput from "@/ittijs/Inputs/EditInput";
import DupDevNum from "@/views/clients_screen/DupDevNum";
import DeviceTypeSelect from "@/ittijs/components/DeviceTypeSelect";
import {deviceInGroup, DeviceType, DeviceTypeGroupTypes} from "@/ittijs/Nomenclatures";
import ModelFormTplSideBySide from "@/ittijs/ModelFormTpl/SideBySide.vue";
import EditButtons from "@/ittijs/EditButtons.vue";
import close from '@/ittijs/dialog_close_mixin';
import {cloneDeep} from "lodash";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";

class Model extends Devices {
	constructor() {
		super('JobServices');
	}
}

const model = new Model;

const DeviceFieldMapping = {
	'dev_old__device_N'             : 'device_N',
	'dev_old__plomba_N'             : 'plomba_N',
	'dev_old__unmount_value'        : 'unmount_value',
	'dev_old__unmount_value2'       : 'unmount_value2',
	'dev_new__device_type_code'     : 'device_type_code',
	'dev_new__device_model_id'      : 'device_model_id',
	'dev_new__device_N'             : 'device_N',
	'dev_new__radio_N'              : 'radio_N',
	'dev_new__plomba_N'             : 'plomba_N',
	'dev_new__mount_value'          : 'mount_value',
	'dev_new__wpl_id'               : 'wpl_id',
	'dev_new__room'                 : 'room',
	'dev_new__radiator_description' : 'radiator_description',
	'dev_new__notes'                : 'notes',
};

/**
 * Which fields to copy from device when editing
 * @type {string[]}
 */
const CopyFieldsEdit = [
	'dev_new__device_type_code',
	'dev_new__device_model_id',
	'dev_new__device_N',
	'dev_new__radio_N',
	'dev_new__plomba_N',
	'dev_new__mount_value',
	'dev_new__wpl_id',
	'dev_new__room',
	'dev_new__radiator_description',
	'dev_new__notes',
];

/**
 * Which fields to copy from device when replacing
 * @type {string[]}
 */
const CopyFieldsReplace = [
	'dev_old__device_N',
	'dev_old__plomba_N',
	'dev_new__device_type_code',
	'dev_new__wpl_id',
	'dev_new__room',
	'dev_new__radiator_description',
	'dev_new__notes',
];

export default {
	mixins: [
		SelectedUtilityMixin,
		close(['open']),
	],
	components: {
		DataLoader,
		EditButtons,
		ModelFormTplSideBySide,
		DeviceTypeSelect,
		DupDevNum,
		EditInput,
		DeviceModel,
	},
	props: {
		title: String,
		id: {},
		readonly: Boolean,
		data: {
			default: () => ({}),
		},
		action: String,
		device: Object,
	},
	data(){
		return {
			open: false,
			model,
			isModelRadio: false,
			lazyData: cloneDeep(this.data) || {},
			errors: {},
		}
	},
	computed: {
		isReplace(){
			return this.lazyData.dev_work_type === 'replace';
		},
		initData(){
			return {}
		},
		activatorColor(){
			return 'green';
		},
		activatorIcon(){
			return 'mdi-swap-horizontal-circle';
		},
		fields(){
			return [
				this.isReplace ? 'dev_old__device_N' : null,
				this.isReplace ? 'dev_old__plomba_N' : null,
				this.isReplace ? 'dev_old__unmount_value' : null,
				this.isReplace && this.value2Visible(this.lazyData['dev_new__device_type_code']) ? 'dev_old__unmount_value2' : null,
				'dev_new__device_type_code',
				'dev_new__device_model_id',
				'dev_new__device_N',
				this.isModelRadio ? 'dev_new__radio_N' : null,
				'dev_new__plomba_N',
				'dev_new__mount_value',
				'dev_new__wpl_id',
				'dev_new__room',
				'dev_new__radiator_description',
				'dev_new__notes',
			]
				.filter(f => !!f)
				.filter(f => !model.getHiddenFieldsByType(this.lazyData['dev_new__device_type_code']).includes(DeviceFieldMapping[f]));
		},
		disabledFields(){
			if (this.readonly) return this.fields;
			return [];
		},
		roomOptionsParams(){
			return {
				utility          : this.selectedUtility,
				device_type_code : this.lazyData && this.lazyData.dev_new__device_type_code || null,
			}
		},
	},
	watch: {
		open(val) {
			if (val) {
				this.lazyData = cloneDeep(this.data);
				let copyFields;
				switch (this.action) {
					case 'insert':
						this.lazyData.dev_work_type = 'edit';
						copyFields = CopyFieldsEdit;
						// UGLY PATCH for default model razpredelitel Qundis Caloric 5.5 code
						if (this.lazyData.dev_new__device_type_code === DeviceType.RAZPREDELITEL) {
							this.lazyData.dev_new__device_model_id = '2';
						}
						break;
					case 'update':
						this.lazyData.dev_work_type = 'edit';
						copyFields = CopyFieldsEdit;
						break;
					case 'replace':
						this.lazyData.dev_work_type = 'replace';
						copyFields = CopyFieldsReplace;
						// UGLY PATCH for default model razpredelitel Qundis Caloric 5.5 code
						if (this.lazyData.dev_new__device_type_code === DeviceType.RAZPREDELITEL) {
							this.lazyData.dev_new__device_model_id = '2';
						}
						break;
				}
				if (this.device && !this.readonly) {
					for (const f of this.fields) {
						if (!copyFields?.includes(f)) continue;
						if (f in DeviceFieldMapping) {
							const fdev = DeviceFieldMapping[f];
							if (this.lazyData[f] === null) {
								this.lazyData[f] = this.device[fdev];
							}
						}
					}
				}
			}
		},
		data(val) {
			this.lazyData = cloneDeep(val);
		},
	},
	methods: {
		doClose(){
			//this.lazyData = cloneDeep(this.data);
			this.open = false;
		},
		onClickSave(){
			if (!this.$refs.form.validate()) return;
			this.doSave();
			this.open = false;
		},
		doSave(){
			this.$emit('update:data', cloneDeep(this.lazyData));
		},
		value2Visible(deviceTypeCode){
			try {
				return deviceInGroup(deviceTypeCode, DeviceTypeGroupTypes.Value2);
			}
			catch (e) {
				return false;
			}
		},
	},
}
</script>